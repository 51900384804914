<template>
  <dialog-component :title="getTitle" :containerWidth="getIsMobile ? '100%' : '40%'" :visible="dialogFormVisible"
    @before-close="closeRolePopUp" v-loading="loading">
    <el-form :model="newRoleForm" label-position="top">
      <el-row :gutter="20">
        <el-col :span="12" class="mt-1">
          <p class="role-title">
            Role Title
            <span class="text-danger">*</span>
          </p>
            <el-input v-model="newRoleForm.title" class="mb-15" autocomplete="off" placeholder="Enter Role Name"></el-input>
            <!-- <p class="error" v-if="
              !newRoleForm.title ||
              !newRoleForm.title.length ||
              newRoleForm.title === ' '
            ">
              * Please add Role title *
            </p> -->
          <p class="group-list">Groups List</p>
            <el-select v-model="newRoleForm.allowed_groups" multiple collapse-tags placeholder="Select Groups" filterable>
              <el-option v-for="groupInfo in allEntitiesGroups" :key="groupInfo._id" :label="groupInfo.name"
                :value="groupInfo._id">
              </el-option>
            </el-select>
        </el-col>
      </el-row>
      <el-row class="mb-1">
        <el-col> <span style="font-weight:bold;color:red" v-if="!roleId">Note: After saving the role, it's essential to allocate customized permissions to the relevant user roles.</span></el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer ml-7">
      <el-row :gutter="5">
        <el-col :span="1" :offset="1" class="mr-2">
          <el-button @click="dialogFormVisible = false" plain>Cancel</el-button>
        </el-col>
        <el-col :span="1" :offset="7">
          <el-button type="primary" icon="el-icon-finished" 
            @click="onSubmit">Save</el-button>
        </el-col>
      </el-row>
    </span>
    <!-- <span class="error" v-if="getError && !getError.success">
      {{ getError.message }}</span
    > -->
  </dialog-component>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["existedRole"],
  data() {
    return {
      dialogFormVisible: false,
      allEntitiesGroups: [],
      newRoleForm: {
        title: "",
        allowed_groups: []
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("roles", ["getRoleErrors", "getSuccess"]),

    ...mapGetters("entityGroups", ["getAllEntityGroupsData"]),
    getTitle() {
      return this.existedRole._id ? "Update Role" : "Add New Role";
    },
  },
  mounted() {
    this.clearErrors();
    this.fetAllEntityGroups();
    this.dialogFormVisible = true;
    if (this.existedRole) {
      this.newRoleForm = { ...this.existedRole };
      this.roleId = this.existedRole._id;
    }
  },
  methods: {
    clearErrors() {
      this.$store.dispatch("roles/resetErrors");
    },
    // :disabled="!newRoleForm.title || !newRoleForm.allowed_groups"
    async onSubmit() {
     
      if (this.newRoleForm && !this.newRoleForm.title) {
        this.$message.error("Please add Role title.!");
      }
      else{
        this.loading = true;
        this.newRoleForm.title = this.newRoleForm.title.replace(/^\s+|\s+$/gm, '');
      if (this.newRoleForm.title) {
        if (this.roleId) {
          await this.updateRole();
        } else {
          await this.addRole();
        }
        this.loading = false;
      } else {
        this.loading = false;
        this.$message.warning("Please Fill the role title");

        return;
      }

      }
     
    },

    async updateRole() {
      try {
        this.newRoleForm.title = this.newRoleForm.title.replace(/^\s+|\s+$/gm, '');
        let params = {
          _id: this.roleId,
          ...this.newRoleForm,
        };
        await this.$store.dispatch("roles/updateRole", params);
        if (this.getSuccess.success) {
          this.$notify.success({
            title: "Success",
            message: "Role Added Successfully.",
          });
          this.closeRolePopUp(true);
          await this.$store.dispatch("roles/resetErrors");
        } else {
          throw "Error";
        }
      } catch (err) {
        // if (this.getRoleErrors.status_code!==200) {
        this.$notify.error({
          title: "Error",
          message: this.getRoleErrors.message ? this.getRoleErrors.message : "Error at Adding role",
        });
        // }
        console.log("updateRole",err);
      }
    },
    async addRole() {
      try {
        let params = {
          ...this.newRoleForm,
        };
        await this.$store.dispatch("roles/createRole", params);
        if (this.getSuccess.success) {
          this.$notify.success({
            title: "Success",
            message: "Role Added Successfully.",
          });
          this.closeRolePopUp(true);
          await this.$store.dispatch("roles/resetErrors");
        } else {
          throw "Error";
        }
      } catch (err) {
        // if (this.getRoleErrors.status_code!==200) {
        this.$notify.error({
          title: "Error",
          message: this.getRoleErrors.message ? this.getRoleErrors.message : "Error at Adding role",
        });
        // }
        console.log("addRole",err);
      }
    },

    closeRolePopUp(status = false) {
      this.$emit("close", status);
      this.$store.dispatch("roles/resetErrors");
    },

    async fetAllEntityGroups() {
      this.loading = true;
      let params = {
        get_all: true,
      };
      await this.$store.dispatch("entityGroups/fetEntityGroupsData", params);
      this.allEntitiesGroups = this.getAllEntityGroupsData
        ? this.getAllEntityGroupsData.data
        : [];
      this.loading = false;
    },
  },
};
</script>

<style>
.err {
  color: #c14e4e;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

</style>
<style lang="scss" scoped>
.btn-class{
  display: flex;

}
.role-title{
  position: relative;
  top:10px;
}
.group-list{
  position: relative;
  top:10px;
}
</style>
<style scoped>
.el-button.is-plain:hover{
  color:var(--primary-color);
  background-color:var(--primary-contrast-color);
  border-color:var(--primary-color);
}
</style>